<template>
 <Layout style="background-color:white;">
    <PageHeader :title="title" :items="items" />
     <div class="container-fluid">
        <div class="col-md-6">

            <b-form-group
                id="input-group-1"
                label="Kullandığınız Şifreniz:"
                label-for="input-1"
                description="Kullandığınız Şifreniz"
            >
              <b-form-input
                  id="input-1"
                  v-model="sistemsifre"
                  type="password"
                  placeholder="Güncel Şifreniz"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" label="Yeni Şifreniz : " label-for="input-2">
              <b-form-input
                  id="input-2"
                  v-model="yenisifre"
                  placeholder="Yeni Şifreniz"
                  type="password"
                  required
              ></b-form-input>
            </b-form-group>
            <b-button type="submit" variant="primary" @click="guncelle">Güncelle</b-button>


        </div>
    </div>
    </Layout>
 </template>   

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {axiosInstance} from '../../../helpers/axios';


  export default {
    name: "Giris",
    components: {
      Layout,
      PageHeader,

    },
    data() {
      return {
        title: "Profil Ayarları",
        items: [{
          text: "Anasayfa"
        },
          {
            text: "Profil Ayarları",
            active: true
          }
        ],
        sistemsifre:null,
        yenisifre:null,
      }
    },
    methods: {
      guncelle()
      {
        var vm = this;
          try {

            axiosInstance.post("kullanicilar/sifre/guncelle",{
              eskisifre:vm.sistemsifre,
              yenisifre:vm.yenisifre,
            }).then((response)=> {

              console.log(response)

            }).catch(error=>{
              console.log(error);
            })

          }catch (e) {

            console.log(e)

          }
      }
      

    }
  }
</script>